
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "PingFangSC";
  src: local("PingFangSC"),
   url("./fonts/PingFangSC/PingFang SC Regular.ttf") format("truetype");
   font-weight: 400;
  }
  
  @font-face {
    font-family: "PingFangSCBold";
    src: local("PingFangSCBold"),
     url("./fonts/PingFangSC/PingFang-SC-Bold.ttf") format("truetype");
    font-weight: 700;
    }
  

 @font-face {
font-family: "PingFangSCMedium";
src: local("PingFangSCMedium"),
url("./fonts/PingFangSC/PingFang-SC-Medium.ttf") format("truetype");
font-weight: 500;
}

@font-face {
 font-family: "PingFangSCSemiBold";
 src: local("PingFangSCSemiBold"),
 url("./fonts/PingFangSC/PingFangSC-Semibold.ttf") format("truetype");
 font-weight: 600;
  }


  @font-face {
    font-family: "AktivGrotesk";
    src: local("AktivGrotesk"),
     url("./fonts/AktivGrotesk/Aktiv-Grotesk-Regular.ttf") format("truetype");
     font-weight: 500;
    }

  
    @font-face {
      font-family: "AktivGroteskMedium";
      src: local("AktivGroteskMedium"),
       url("./fonts/AktivGrotesk/Aktiv-Grotesk-Medium.ttf") format("truetype");
       font-weight: 600;
      }
      
  @font-face {
    font-family: "AktivGroteskBold";
    src: local("AktivGroteskBold"),
     url("./fonts/AktivGrotesk/Aktiv-Grotesk-Bold.ttf") format("truetype");
     font-weight: 700;
    }