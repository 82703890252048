.signUp_panel {
    padding: 20px 10px 10px 18px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 17%), 0 0 6px 0 #d8d8d8;
    background-color: #fff;
    width: 470px;
    left:35%;
    top:50%;
    position: sticky;
    margin-top: 50px
}

.signUp_heading {
    font-family: PingFangSCSemiBold;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #0749b3;
}