.cron_builder_bordering {
    border: 1px solid #ddd;
    border-top: none;
    text-align: center;
    padding: 10px;
    background: #fff;
}
.cron_builder_bordering input, .cron_builder_bordering select {
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding-left: 5px;
    cursor: pointer;
}
.df {
    display: flex;
}
.cron-builder-bg {
    background-color: #fc9558;
    color: white;
    text-align: center;
    margin-bottom: 4px;
    padding: 8px 0px;
}
.cron_builder_bordering select {
    background-color: white;
    width: 75px;
    cursor: pointer;
    padding: 4px 2px;
    border-radius: 4px;
}
.cron_builder_bordering select option:hover {
    background-color: #fc9558;
}
.well-small input {
    width: auto !important;
}
.cron_builder_bordering  input[type='radio'] {
    margin-top: 0px;
    vertical-align: middle;
}
.cron_builder {
    border: 1px solid #d0cbcb;
    padding: 5px;
    background-color: #dddef13d;
    width: 100%;
    max-width: 600px;
}
.text_align_left {
    text-align: left;
}
.cron_builder .nav li {
    cursor: pointer;
    flex: 0 0 85px;
    text-align: center;
}
.cron_builder .nav li a {
    color:#fc9558;
    display: inline;
}
.cron_builder .nav-tabs {
    list-style-type: none;
    margin: 10px 0px 10px 0px;
    padding: 0;

}
.cron_builder .nav-tabs li  {
    display: inline;
    margin-left: 20px;
    padding: 5px ;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 17%), 0 0 6px 0 #d8d8d8;

}
.cron_builder .nav-tabs .nav-link:focus, .cron_builder .nav-tabs .nav-link:hover {
    border-color: transparent transparent transparent;
    background-color: #eeeeee;
}
.cron_builder .nav-tabs .nav-item.show .nav-link, .cron_builder .nav-tabs .nav-link.active {
    border-color: #dee2e6 #dee2e6 #fff;
    background-color: #ffffff;
}
.cron_builder { 
    font-size: 14px;
}
.cron_builder .well {
    min-height: 20px;
    padding: 19px;
    text-align: left;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}
@media screen and (max-width:767px) {
    .cron_builder .nav li {
        cursor: pointer;
        flex: 0 0 65px;
        text-align: center;
    }
}

