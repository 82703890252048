/* 
 .searchBox.ant-input-affix-wrapper > input.ant-input {
    background-color: #f5f6f7;
  }
  */
  /* .searchBox input.ant-input:focus{
    outline: none;
 } */
  
  /* .searchBox span.ant-input-prefix img,
  .searchBox span.ant-input-suffix img{
    width: 16px;
    height: 16px;
    margin: 20px;
    margin-right: -21px;
    color: #aeaeae;
  } */

  .ant-input-affix-wrapper.searchBox {
    color: #454d64;
    font-size: 14px;
    width: 230px;
    height: 35px;
    margin: 2px 0;
    border-radius: 6px;
    border: solid 1px #d8dddf;
    background-color: #fff;
  }
 
 