.gauge_card {
    /* width: 323px; */
    height: 218px;
    margin: 3px 19px 35px 12px;
    padding: 30px 0px 44px 0px;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
}

.gauge_heading {
    margin: 0 0.8px 23px 0;
  font-family: AktivGrotesk;
  
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8c8c8c;
}

.stat_card{
    width: 250px;
  height: 170px;
  /* margin: 28px 13px 40px 67px; */
  padding: 15px 0 33px;
  border-radius: 3px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.refresh_filter .ant-select-selector{
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.addon_color .ant-input-group-addon{
  background-color: rgb(213 209 209 / 65%);
}