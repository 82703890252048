.login_panel {
    /* padding: 20px 10px 10px 18px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 17%), 0 0 6px 0 #d8d8d8;
    background-color: #fff;
    width: 400px;
    left:37%;
    top:50%;
    position: sticky;
    margin-top: 140px */
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 515px;
    height: 300px;
    /* margin: 25px 0 107px; */
    padding: 28px 12px 51px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(200, 200, 200, 0.5);
    background-color: #fff;
    justify-content: center

}
.loginLogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 280px;
  /* height: 90px; */
  margin-top: 60px;
  margin-bottom: 30px;
  /* margin: 0 133px 25px; */
  /* margin: -22px 1009px 0px 12px; */
  /* padding: 14px 20px 12px 24px; */
  background: #f0f2f5;
}
.login_heading {
   
  /* font-family: AktivGroteskMedium;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: black; */
  width: 73px;
  height: 24px;
  margin: 0 1px 0 16px;
  font-family: AktivGroteskMedium;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: var(--black);
}
.divider-1 {
  /* border-bottom: 1px solid #FFF; */
  border: solid 1px var(--white);

  background-color: #DADADA;
  height: 1px;
  margin: 0.5em 0px 1.5em;
}

.divider-1 span {
  display: block;
  width: 70px;
  height: 3px;
  margin-left: 18px;
  margin-top: 20px;
    background-color: #fc9558;
    border-radius: 52px;
}
.content_form_panel {
  border-radius: 7px;
  margin: 10px;
  padding: 20px 10px 0px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: #fff;
}