.content_panel {

  min-height: 450px;
  padding: 29px 20px 20px 14px;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(200, 200, 200, 0.5);
  background-color: #fff;
}

.header-title {
 margin-left: -100px;
 float: right;
 font-family: AktivGrotesk;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.content_panel_heading {
  width: 261px;
  height: 24px;
  margin: 2px 178px 1px 14px;
  font-family: AktivGroteskMedium;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: black;
}
.collapse_panel_heading {
 
  margin: 2px 178px 1px 14px;
  font-family: AktivGroteskMedium;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  color: black;
}
.ant-layout-content div.ant-breadcrumb {
  text-align: left;
  padding: 11px;
  font-family: AktivGrotesk;
  font-size: 12px;
}

img {
  transform: translateX(-22px);
}
.input-class{
  height: 33px;
  border-radius: 7px;

}
