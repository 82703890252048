.logo {
  /* height: 32px; */
  width: 160px;
  margin: 20px;
  /* margin-left: 40px; */
  display: inline;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  position: fixed;
  z-index: 1;
  width: 1085px;
  height: 24px;
  margin: 0 0 34px 40px;
  padding: 4px 13px 5px 984px;
  border-radius: 7px;
  box-shadow: 0 2px 9px 0 rgba(200, 200, 200, 0.5);
  background-color: #fff;
}
.user-name {
  width: 35px;
  height: 15px;
  margin: 10px 0 0 7px;
  font-family: AktivGrotesk;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color:#000000;
  cursor: pointer;
}
h1.ant-typography, .ant-typography h1{
  margin-top: -26px
}

h1.HeaderTitle{
  /* position: absolute;
  font-size: 18px;
  position: absolute;
  margin: -15px 0px 16px 0px;
  white-space: nowrap;
  font-family: PingFangSCSemiBold;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #002766; */
  right: 225px;
}

.HeaderTitle.adjustTitle{
  right: 110px;
}

.mdapLogo1{
  width: 210px;
  height: 73px;
  margin: -22px 1009px 0px 12px;
  /* padding: 14px 20px 12px 24px; */
  background-color: #fff;
}

.mdapLogo2{
  width: 80px;
  height: 63px;
  margin: -22px 1009px 0px 22px;
  padding: 14px 12px 12px 12px;
  background-color: #fff;
}

.ant-layout-sider{
  height: 1103px;
  /* box-shadow: 2px 0 2px 0 rgb(0 0 0 / 11%); */
  background-color: #fff;
  width: 208px;
}

/* span.ant-menu-title-content{
  font-family: PingFangSCBold;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff7a38;
} */

/* .menuitem.ant-menu-title-content{
  font-family: PingFangSC;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  color:  black;
} */

.ant-menu-submenu-arrow{
  color: #bfbfbf;
}

.ant-menu-item.ant-menu-item-selected span.ant-menu-title-content{
  font-family: AktivGrotesk;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
color: #313131;
}

.ant-menu-item span.ant-menu-title-content{
  font-family: AktivGrotesk;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  color: #313131;
}
.ant-menu-item:hover{
  
  border-radius: 10px;
    background-color: #f4f4f4;
  
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
color:#bfbfbf;
}
.anticon.anticon-database.ant-menu-item-icon:hover{
color:#bfbfbf;
}

/* .ant-menu-submenu-title{
background-image: url("../../assets/images/icon-monitoring.svg");
background-repeat: no-repeat;
} */

.ant-menu.ant-menu-sub.ant-menu-inline{
background-color: #fff;

}
div.ant-layout-sider-trigger{
width: 0px!important;
top: 72px;
left: 158px;
}

aside.ant-layout-sider-has-trigger
{
overflow: unset!important;
}
div.ant-layout-sider-trigger{
background-repeat: no-repeat!important;
width: 25px!important;
height: 24px;
}
div.ant-layout-sider-trigger.menuClose{
background-image: url("../../assets/images/icon-menu-close.svg")!important; 
}
div.ant-layout-sider-trigger.menuOpen{
margin-left: -134px;
background-image: url("../../assets/images/icon-menu-open.svg")!important; 

}
.main-sidebar span.anticon-right{
opacity: 0!important;

}
.main-sidebar span.anticon-left{
opacity: 0!important;
}
/* span.anticon.anticon-left{
background-image: url("../../assets/images/menuClose.svg")!important;
background-repeat: no-repeat!important;
width: 120px;
height: 120px;
}
span.anticon.anticon-right{
background-image: url("../../assets/images/menuOpen.svg")!important;
background-repeat: no-repeat!important;
width: 120px;
height: 120px;
} */

.footer{
  height: 17px;
 
  margin: 67px 0 27px 830px;
  font-family: AktivGrotesk;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}
